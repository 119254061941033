export default () => (
    {
        navIsOpen: false,
        isMobile: false,
        width: 0,
        // Toggle the two below if you need to keep a menu open for stylng or troubleshooting, enter the name of the top-level menu item you want to keep open.
        // submenuIsOpen: 'Dental Services',
        submenuIsOpen: false,
        
        init(){
            // Set screen width on load
            this.width = window.innerWidth;
            this.setIsMobile();
        }, 

        setIsMobile(){
            this.isMobile = this.width < 1280;
        },


    }
);
